import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

const HomePage = lazy(() => import("../pages/Home"));
const Notfound = lazy(() => import("../pages/Notfound"));

const routerConfig = [
  {
    path: '/',
    component: HomePage
  },
  {
    path: '*',
    component: Notfound
  }
];

const Loading = () => {
  return (
    <></>
  )
}

const AppRouter = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Router basename="/">
        <Switch>
          {routerConfig.map((n, index) => {
            return <Route path={n.path} exact component={n.component} key={index}></Route>;
          })}
        </Switch>
      </Router>
    </Suspense>
  );
}

export default AppRouter;
